<template>
    <page title="请假详情" :hasBack="true" textAlign="left">
        <div class="leave-detail-page">
            <common-block class="leave-msg" title="请假信息">
                <leave-des :leave-msg="leaveMsg" />
                <triangle :type="leaveMsg.status" />
            </common-block>
            <common-block class="process" title="审批流程">
                <common-step :stepList="stepList" :styleApprove="true" />
            </common-block>
            <common-block class="reason" :border="false" title="审批意见">
                <van-field v-model="reason" rows="4" autosize type="textarea" disabled />
            </common-block>
        </div>
    </page>
</template>
<script>
    import Page from '@/components/page/Page';
    import CommonBlock from '@/components/commonBlock/CommonBlock';
    import LeaveDes from '@/components/leaveDes/LeaveDes';
    import Triangle from '@/components/triangle/Triangle';
    import CommonStep from '@/components/commonStep/CommonStep';
    import {Button, Field} from 'vant';
    import { reactive, ref } from 'vue';
    import {useRoute} from "vue-router";
    import commonRequest from '@/services';
    export default {
        name: 'LeaveDetail',
        components: {
            [Page.name]: Page,
            [CommonBlock.name]: CommonBlock,
            [LeaveDes.name]: LeaveDes,
            [Triangle.name]: Triangle,
            [CommonStep.name]: CommonStep,
            [Button.name]: Button,
            [Field.name]: Field,
        },
        setup() {
            const route = useRoute();
            const leaveMsg = ref({
            });
            const reason = ref('');
            const stepList = reactive([
            ]);
            commonRequest({ action: 11006, id: route.query.id }).then((res) => {
                leaveMsg.value = res.data;
                stepList[0] = {title: '审批人', content: res.data.teacherName + '老师'};
                stepList[1] = {title: '审批意见'};
                if (res.data.status === 0) {
                    stepList[1].content = '待批假';
                    stepList[1].color= '#EE8A32';
                } else if (res.data.status === 1) {
                    stepList[1].content = '已批假';
                    stepList[1].color= '#1BD188';
                } else if (res.data.status === 4) {
                    stepList[1].content = '已完成';
                    stepList[1].color= '#E64141';
                } else if (res.data.status === 3) {
                    stepList[1].content = '已撤销';
                    stepList[1].color= '#999999';
                } else if (res.data.status === 2) {
                    stepList[1].content = '已驳回';
                    stepList[1].color= '#F7F9FA';
                }
                if (res.data.verifyDesc) {
                    stepList[2] = {title: '审批意见', content: res.data.verifyDesc};
                }
                if (res.data.verifyTime) {
                    stepList[3] = {title: '审批时间', content: res.data.verifyTime};
                }
            });
            return {
                leaveMsg,
                stepList,
                reason,
            };
        },
    };
</script>
<style lang="less" scoped>
    .leave-detail-page {
        .process {
            /deep/ .common-inner {
                border: none;
                margin-top: -12.5px;
            }
        }
        .btn-wrapper {
            display: flex;
            justify-content: space-between;
            padding: 0 23px;
            box-sizing: border-box;
            margin-top: 35px;
            margin-bottom: 20px;
            &.center {
                justify-content: center;
            }
            /deep/ .van-button.van-button--primary {
                padding: 0 29px;
            }
            /deep/ .van-button.van-button--warning {
                padding: 0 29px;
            }
        }
    }
</style>
